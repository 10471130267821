


































































import { OrderStatus } from "@/models";

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown
} from "element-ui";
import { Settings, SettingsOrderStatuses } from "@/services/SOLO";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    OrderStatusesFormModal: () => import("./OrderStatusesFormModal.vue"),
    OrderStatusesIsVisiblePickup: () =>
      import("./OrderStatusesIsVisiblePickup.vue"),
    OrderStatusesIsVisibleDeliver: () =>
      import("./OrderStatusesIsVisibleDeliver.vue"),
    Translations,
  },
  computed: {},
  mixins: [translations],
})
export default class OrderStatusesTable extends Vue {
  translate!: Function;
  activeStatus: OrderStatus | null = null;
  @Prop() orderStatuses!: Array<OrderStatus>;
  $refs!: {
    orderStatusesFormModal: any;
  };

  initEdit(row: OrderStatus) {
    this.activeStatus = row;
    this.$refs.orderStatusesFormModal.open = true;
  }
}
